<script setup lang="ts">
import { cn } from '@/lib/utils';
import { useVModel } from '@vueuse/core';

const props = defineProps<{
  defaultValue?: string | number;
  modelValue?: string | number;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
}>();

const emits = defineEmits<{
  'update:modelValue': [payload: string | number];
  focusin: [];
  focusout: [];
}>();

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
});
</script>

<template>
  <div class="relative">
    <input
      v-model="modelValue"
      :class="
        cn(
          'flex h-15 w-full rounded-none border-input bg-background px-3 text-regular-20 ring-offset-background file:border-0 file:bg-transparent placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50   text-regular-20 !text-iq-grey-dark !bg-iq-beige shadow-iq-inset placeholder:!text-iq-grey-light placeholder:text-regular-20  ',
          $attrs.class ?? ''
        )
      "
      :placeholder="props.placeholder"
      :type="props.type"
      :disabled="props.disabled"
      @focusin="emits('focusin')"
      @focusout="emits('focusout')"
    />
    <ClientOnly>
      <Icon
        v-if="modelValue && !props.disabled"
        class="absolute w-4 h-4 top-5.5 right-4 hover:bg-iq-grey-light/30 hover:cursor-pointer"
        name="heroicons:x-mark-16-solid"
        @click="modelValue = ''"
      />
    </ClientOnly>
  </div>
</template>
